import Image from "next/image";
import classNames from "classnames";
import { useTranslations } from "next-intl";

const providers = [
  { name: "Sixt", logo: "/hero/sixt.png", },
  { name: "Dollar", logo: "/hero/dollar.png", },
  { name: "Budget", logo: "/hero/budget.png", },
  { name: "National", logo: "/hero/national.png", },
  { name: "Enterprise", logo: "/hero/enterprise.png", },
  { name: "Avis", logo: "/hero/avis.png", },
  { name: "Hertz", logo: "/hero/hertz.png", },
  { name: "Ace", logo: "/hero/ace.png", },
  { name: "Alamo", logo: "/hero/alamo.png", },
  { name: "Europcar", logo: "/hero/europcar.png", }
];

type Props = {
  type?: string;
  locationName?: string;
  agencyData?: {
    agency: Agency;
    provider: Provider;
  }[];
};

const RentalPartners = ({ type, agencyData, locationName, }: Props) => {
  const agencyList = agencyData?.filter((agency) => agency.agency.name !== "Surprise Agency");
  const t = useTranslations("LP3");

  if (type === "agency") return (
    <div className="w-full bg-white">
      <div className="mx-auto max-w-7xl pb-[20px] mt-[20px]">
        <div className={classNames("flex flex-col justify-center items-center px-2 w-full")}>
          <h2 className={classNames("font-medium text-center text-[15px] text-my-dark-blue mb-[20px] mt-[22px]")}>
            {t("rentalPartnersAt", { locationName })}
          </h2>
        </div>

        <div className="flex flex-wrap items-center justify-center lg:justify-center justify-items-center gap-x-[14px] lg:gap-x-[24px] gap-y-[23px] lg:space-x-4 mx-auto w-full">
          {agencyList?.map((data, index) => (

            <div className="flex justify-center items-center p-2" key={index} onClick={() => {
              document.getElementById("search_button")?.click();
            }}>
              <img
                className="min-w-[60px] max-h-[30px]"
                src={`https://content.r9cdn.net/rimg/provider-logos/cars/h/${data.agency.icon}`}
                alt={data.agency.name}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className={classNames("flex flex-col items-center justify-center w-full px-2 p-10", { "bg-white p-[20px]": type === "lp2" })}>
      <h2 className={classNames("font-medium text-[15px] text-my-dark-blue text-center mb-[20px]", type !== "lp2" && "mt-[22px]")}>
        {t("rentalPartners")}
      </h2>
      <div
        className="grid grid-cols-5 lg:flex lg:max-w-7xl items-center justify-center lg:justify-between justify-items-center gap-x-[14px] gap-y-[23px] lg:space-x-4 mx-auto w-full"
        onClick={() => { document.getElementById("search_button")?.click(); }}
      >
        {providers.map(provider =>
          <Image
            key={provider.name}
            src={provider.logo}
            width={60}
            height={30}
            alt={provider.name + " logo"}
            className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
          />
        )}
      </div>
    </div>
  );
};

export default RentalPartners;

import classNames from "classnames";
import { forwardRef, MouseEventHandler, useMemo } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { fireEvent } from "@/utils/fireEvent";
import { defaultLocale, languages } from "@/constants";
import "react-datepicker/dist/react-datepicker.css";

languages.forEach(({ locale, dateLocale }) => { registerLocale(locale, dateLocale); });

type CustomDatePickerProps = {
  maxDate?: Date;
  selected: Date;
  onChange: Function;
  minDate?: Date;
  logKey: string;
  label: string;
};

const CustomDatePicker = ({ selected, onChange, minDate: minDate, logKey, label, maxDate }: CustomDatePickerProps) => {
  const { locale } = useRouter();
  const ButtomDateInput = useMemo(() => {
    const Component = forwardRef(
      ({ value, onClick }: { value?: any; onClick?: MouseEventHandler<HTMLButtonElement>; }, ref: any) => (
        <div className="flex relative flex-1 items-stretch w-full">
          <button
            name="date picker"
            className="flex gap-2 justify-start items-center px-3 py-4 w-full text-base font-medium text-left border-none bg-inherit focus:outline-nonen ring-none text-my-dark-blue focus:ring-0 focus:border-none focus:outline-none lg:text-lg"
            onClick={onClick}
            ref={ref}
          >
            <Image src="/hero/calendar.svg" alt="location pin icon" height={24} width={24} />
            {value}
          </button>
        </div>
      )
    );

    Component.displayName = "BottomDateInput";
    return Component;
  }, []);

  return (
    <div className="relative w-full">
      <ReactDatePicker
        customInput={<ButtomDateInput />}
        selected={selected}
        onInputClick={() => { fireEvent(logKey); }}
        onChange={(date: Date) => { onChange(date); }}
        dateFormat="E, MMM d"
        dayClassName={(date: Date) => classNames("!rounded-full !h-8 !w-8 !inline-flex !items-center !justify-center", {
          "!bg-accent-400 !text-black !font-bold": date.toDateString() === selected.toDateString(),
        })}
        calendarClassName="!bg-white"
        dropdownMode="scroll"
        minDate={minDate}
        maxDate={maxDate}
        popperPlacement="bottom"
        locale={locale ?? defaultLocale}
        fixedHeight
      />
    </div>
  );
};

export default CustomDatePicker;

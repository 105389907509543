import axios from "axios";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import { observer } from "mobx-react-lite";
import { airportStore, dropoffStore } from "@/stores";
import { usePickup } from "@/hooks/usePickup";

type Props = {
  inputName: string;
  id: string;
  tag: string;
  locale: string;
  logAnalyticsEvent: Function;
  dropOff: boolean;
  setTogglePage: Function;
  type: string;
};

function SearchPage({ id, tag, locale = "en", logAnalyticsEvent, dropOff, setTogglePage, type }: Props) {
  const ref = useRef(null);
  const [displayOptions, setDisplayOptions] = useState(false);
  const [places, setPlaces] = useState([]);
  const { setPickupLocation } = usePickup();
  const [filter, setFilter] = useState(type !== "airport" ? undefined : dropOff ? dropoffStore.displayName : airportStore.displayName);
  const [debouncedFilter] = useDebounce(filter, 300);
  const [lc, lcCC] = (useRouter().locale ?? "en-US").split("-");
  const t = useTranslations("LP3");

  const placesQuery = useQuery(
    ["places", debouncedFilter],
    async () => axios.get(`https://www.kayak.com/mv/marvel?f=j&s=81&v=v1&where=${debouncedFilter}&lc=${lc}&lc_cc=${lcCC}`),
    { enabled: debouncedFilter !== undefined }
  );

  useEffect(() => {
    if (placesQuery.isSuccess) {
      setPlaces(placesQuery.data.data);
    }
  }, [placesQuery.data]);

  useEffect(() => {
    // @ts-ignore
    ref.current && ref.current?.focus();
  }, []);

  return (
    <div id={id} className="flex relative flex-col p-3 w-screen h-screen overflow-none md:hidden" >
      <div className="flex sticky top-0 px-2">
        <button className="w-8" onClick={() => { setTogglePage(false); }}>
          <Image src="/left-chevron.svg" alt="Close page button placeholder" height={28} width={28} />
        </button>
        <div className="flex flex-grow items-center justify-center h-fit bg-[#F3F6F9] rounded-[10px] py-2">
          <input
            autoFocus={true}
            placeholder={dropOff ? t("inputDropoff") : t("inputPickup")}
            onFocus={(e) => {
              e.preventDefault();
              e.target.select();
            }}
            required={true}
            autoComplete="off"
            type="text"
            name={"inputName"}
            value={filter ?? (dropOff ? dropoffStore.displayName : airportStore.displayName)}
            onChange={(event) => {
              setFilter(event.target.value);
              setDisplayOptions(true);
            }}
            onMouseUp={(e) => e.preventDefault()}
            onClick={(event: any) => {
              event.target.select();
              logAnalyticsEvent(tag.concat("_clicked"));
              setDisplayOptions(true);
            }}
            className="w-full ring-0 border-0 outline-none focus:border-0 focus:outline-none focus:ring-0 bg-[#F3F6F9]"
          />
        </div>
      </div>
      {displayOptions && places?.length !== 0 && (
        <ul>
          {places.map((option: any) => {
            const handleClick = () => {
              if (!dropOff) setPickupLocation(option);
              setFilter(option.smartyDisplay);
              logAnalyticsEvent(tag.concat("_selected"), {
                [tag]: option.smartyDisplay,
              });
              setDisplayOptions(false);
              setTogglePage(false);
              dropOff ? dropoffStore.setDropoff(option.displayname, option.apicode, option.citynameshort)
                : airportStore.setAirport(option.displayname, option.apicode, option.citynameshort);
            };

            return (
              <li key={option.id} onMouseDown={handleClick} className="flex gap-3 px-3 py-2 w-full bg-white border-b cursor-pointer hover:bg-background-400">
                <div className="flex justify-center items-center w-8">
                  <SearchIcon iconType={option.displayType.type} />
                </div>
                <div className="flex flex-col items-start py-2">
                  <span className="font-medium line-clamp-2">
                    {option.smartyDisplay}
                  </span>
                  <span className="text-sm line-clamp-1">
                    {`${option.displayType.displayName} - ${option.locationname}`}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default observer(SearchPage);

function SearchIcon({ iconType }: { iconType: string; }) {
  if (iconType === "city") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="26"
        fill="none"
      >
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1.5 24.25h25"
        ></path>
        <path
          fill="#000"
          d="M9.25 11.75h-5C3.56 11.75 3 12.31 3 13v10c0 .69.56 1.25 1.25 1.25h5c.69 0 1.25-.56 1.25-1.25V13c0-.69-.56-1.25-1.25-1.25zM23.75.5h-10c-.69 0-1.25.56-1.25 1.25V23c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25V1.75C25 1.06 24.44.5 23.75.5z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17.5 18.005H20M6.75 15.5v5m10.75-8.12H20m-2.5-5.625H20"
        ></path>
      </svg>
    );
  } else if (iconType === "airport") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="none"
      >
        <path
          fill="#000"
          d="M26.85 17.125l-9.975-5.875V4.375A1.872 1.872 0 0015 2.5a1.872 1.872 0 00-1.875 1.875v6.875L3.15 17.125c-.4.225-.65.663-.65 1.125 0 .875.837 1.5 1.675 1.262l8.95-2.625v6.863L10.3 25.438a.634.634 0 00-.3.537v.725c0 .413.387.713.775.613l3.65-.913.575-.15.475.113.525.137 2.375.6.837.212c.4.1.776-.2.776-.612v-.725a.619.619 0 00-.3-.538l-2.813-1.687v-6.875l8.95 2.625a1.301 1.301 0 001.675-1.25c0-.462-.25-.9-.65-1.125z"
        ></path>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="26" fill="none">
      <path
        fill="#000"
        d="M10 .5a10 10 0 00-10 10c0 2.365.502 3.912 1.875 5.625L10 25.5l8.125-9.375C19.497 14.412 20 12.865 20 10.5a10 10 0 00-10-10z"
      ></path>
      <path
        fill="#fff"
        d="M10 14.25a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
      ></path>
    </svg>
  );
}

import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useAd = create<CarAdState, any[]>(persist(
  (set) => ({
    adData: null,
    open: false,
    onSkip: null,
    setAdData: (adData) => { set({ adData }); },
    openAd: (onSkip) => {
      set(state => {
        if (!state.adData?.inlineItems.length) {
          onSkip();
          return {};

        } else {
          return { open: true, onSkip };
        }
      });
    },
    closeAd: () => { set({ open: false, adData: null }); },
  }),
  {
    name: "ad-data",
    partialize: ({ adData }) => ({ adData })
  }
));

import { useTranslations } from "next-intl";

const Features = () => {
    const t = useTranslations("LP3");

    return (
        <div className="flex items-center justify-start text-[12px] text-white bg-black bg-opacity-30 mt-auto gap-[25px] py-[10px] px-[22px] rounded-md font-medium">
            <div className="flex items-center justify-center gap-[7px] border-e border-[#948F8B] pe-4">
                <img
                    src="/hero/check-circle.svg"
                    alt="Check circle icon"
                    className="w-[24px] h-[24px]"
                />
                <p>{t("features1")}</p>
            </div>
            <div className="flex items-center justify-center gap-[7px] border-e border-[#948F8B] pe-4">
                <img
                    src="/hero/check-circle.svg"
                    alt="Check circle icon"
                    className="w-[24px] h-[24px]"
                />
                <p>{t("features2")}</p>
            </div>
            <div className="flex items-center justify-center gap-[7px] ">
                <img
                    src="/hero/check-circle.svg"
                    alt="Check circle icon"
                    className="w-[24px] h-[24px]"
                />
                <p>{t("features3")}</p>
            </div>
        </div>
    );
};

export default Features;
